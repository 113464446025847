<template>
  <div>
    <b-row>
      <b-col lg="12" md="12">
        <div class="mt-2">
          <h3>Gestion des fiches comptables</h3>
        </div>
      </b-col>
      <b-col lg="12" md="12">
        <div class="mt-2">
          <p v-if="canViewBasedOnRole(currentRole, [Roles.ORGANIZATION_ADMIN.role, Roles.ORGANIZATION_COORDINATOR.role])">
            Avant de modifier vos fiches comptables, assurez-vous d'avoir ajouté
            tous les produits que vous voudrez associer à chacune des fiches
            comptables.
          </p>
        </div>
      </b-col>
    </b-row>

    <b-nav v-if="group" class="mt-2" content-class="mt-2" justified tabs>
      <b-nav-item
        :to="`/groups/${group.id}/billing/sheets`"
        exact
        exact-active-class="active"
      >
        <span class="d-none d-md-inline">Fiches comptables</span>
      </b-nav-item>
      <b-nav-item
        :to="`/groups/${group.id}/billing/products`"
        exact
        exact-active-class="active"
      >
        <span class="d-none d-md-inline">Produits</span>
      </b-nav-item>
    </b-nav>

    <router-view />
  </div>
</template>

<script>
import { BRow, BCol, BNav, BNavItem } from "bootstrap-vue";
import Roles from "@/modules/authnz/models/role";
import {
  AUTH_STORE_NAMESPACE,
  AUTHENTICATED_USER_GETTER,
} from "@/modules/authnz/models/store";

export default {
  components: { BRow, BCol, BNav, BNavItem },
  data() {
    return {
      Roles,
    };
  },
  methods: {
    canViewBasedOnRole(currentRole, allowedRoles) {
      return Roles.roleIsAllowed(currentRole.role, allowedRoles);
    },
  },
  computed: {
    availableRoles() {
      return Roles;
    },
    currentRole() {
      return this.$store.getters[
        `${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`
      ].role;
    },
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
  },
};
</script>
